<template>
  <div class="container py-0 product-width" v-if="productDetails.product">
    <div class="product-info">
      <div class="row ma-0">
        <VideoModal
          :dialog="openVideoModal"
          :video="videoUrl"
          @close="openVideoModal = false"
        />
        <div class="col-md-1 col-12 order-md-1 order-2 pa-0">
          <ul class="picture list-unstyled pa-0">
            <li
              v-for="(image, index) in selectedGallery"
              :key="index"
            >
              <img
                v-lazy="image"
                width="72"
                height="67"
                translate="no"
                :alt="getImageName(image)" :title="getImageName(image)" 

                @click="changeMainImage(image, index)"
              />
            </li>
            <li>
              <div class="video-container"
                  :style="{background: `url(${selectedGallery.length  ? selectedGallery[0] : ''})`}"
                  v-if="videoUrl"
                  @click="selectVideo">
                <img width="72" height="67" src="/img/video_icon.svg" alt="video_icon" title="video_icon" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-6 col-12 order-md-2 order-1 position-relative pa-0">
          <div
            class="bottom-left"
            >Sale</div
          >
          <div @click="openImage(selectedImg.index)" class="top-right">
            <img src="/img/search.svg" alt="search" title="search" />
          </div>
          <div @click="openImage(selectedImg.index)" class="mainImg">
            <div style="width: 100%;height: 100%;" v-show="selectedImg?.photo === 'video'" >
              <div v-if="isYoutube(videoUrl)" ref="youtubeContainer" class="center" v-html="videoUrl">
              </div>
              <video
                    v-else
                    class="product-info__video"
                    ref="videoRef"
                    loop
                    :src="'https://admin.tumar.com' + videoUrl">
              </video>
            </div>
            <img
              v-show="selectedImg?.photo !== 'video'"
              v-lazy="selectedImg?.photo && selectedImg?.photo"
              class="product-info__preview"
              :alt="getImageName(selectedImg?.photo)" :title="getImageName(selectedImg?.photo)" 

              translate="no"
            />
          </div>
        </div>
        <div class="col-md-5 col-12 order-md-3 order-3 col py-0 pl-15">
          <div class="product-page">
            <nav>
              <ol class="breadcrumb pa-0">
                <li class="breadcrumb-item">
                  <router-link :to="{ path: '/' }">Home</router-link>
                  <span class="mx-2">></span>
                </li>
                <li
                  v-for="(category, index) in productCategoriesPath"
                  :key="index"
                  class="breadcrumb-item"
                >
                  <router-link
                    :to="`/categories/${category?.url}?categoryId=${category?.id}`"
                  >
                    {{ category?.title?.ru }}</router-link
                  >
                </li>
              </ol>
            </nav>
          </div>
          <h1 class="product-ttl" itemprop="name" @click="payByPaypal()">{{
            productDetails?.product?.name?.ru
          }}</h1>
          <h3 class="product_price">
            <span v-show="productPrice" class="product_price notranslate">
              {{ productPrice }} KGS
            </span>
          </h3>

          <div class="stars">
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star</v-icon>
            <v-icon color="#ECC366">mdi-star-half-full</v-icon>
            (412)
          </div>
          <div class="block-info-text">
            <p
              ref="description"
              itemprop="description"
              v-html="productDetails?.product?.description?.ru"
              class="mb-6"
            >
            </p>
          </div>
          <div class="color">
            <h3 class="mb-5">Colors</h3>
            <ul class="list-color list-unstyled pa-0">
              <li
                v-for="color in colors"
                :key="color?.id"
                :class="{ active: selectedColor?.id === color?.id }"
                class="item-color"
                @click="selectColor(color)"
                :style="{
                  background: `url(${baseUrl + color.photo_path})`
                }"
              >
                <span>{{ color }}</span>
              </li>
            </ul>
          </div>

          <div class="size">
            <h3 class="size__title">Size</h3>
            <div class="d-flex justify-content">
              <div class="w-100">
                <ul class="list-unstyled pa-0">
                  <li
                  itemprop="size"
                    v-for="(size, i) in sizes"
                    :key="i"
                    class="size-item"
                    :class="{active: size === selectedSize, hoverable: size !== selectedSize}"
                    @click="selectSize(size)"
                  >
                    <span>{{ size }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="size">
            <h3 class="size__title">Thickness</h3>
            <div class="d-flex justify-content">
              <div class="w-100">
                <ul class="list-unstyled pa-0">
                  <li
                      v-for="(item, i) in thickness"
                      :key="i"
                      class="size-item"
                      :class="{
                        active: item === selectedThickness,
                        hoverable: item !== selectedThickness
                      }"
                      @click="selectThickness(item)"
                  >
                    <span>{{ item }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <v-divider></v-divider>
          <div class="row btn-add">
            <div class="col-2">
              <input
                v-model="countOfProduct"
                type="number"
                style="min-width: 60px;border: 1px solid #f2f2f2; height: 100%"
                class="rounded w-100 px-3"
                min="1"
              />
            </div>
            <div class="col-10 d-flex">
              <v-btn
                  :disabled="!isValid"
                color="#CFEBCC"
                class="flex-grow-1"
                :elevation="0"
                :ripple="false"
                @click="addToCart"
                >Add to cart</v-btn
              >
            </div>
          </div>
          <v-divider></v-divider>
          <div class="social_icon">
            <ul class="social_icon-item list-unstyled pa-0">
              <li
                v-for="(icon, index) in social_medias"
                @click="onShareProduct(icon.link)"
                :key="index"
                ><v-icon color="rgb(128, 128, 128)" size="14">{{
                  icon.icon
                }}</v-icon></li
              >
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 mb-200">
      <div class="col-md-7 col-12">
        <div class="description">
          <div class="accordeon">
            <v-tabs
              hide-slider
              v-model="tab"
              color="#666666"
              class="nav nav-tabs mb-3"
            >
              <v-tab class="nav-item">Description</v-tab>
            </v-tabs>

            <div key="0" v-if="tab === 0" class="product__content">
              <v-expansion-panels
                v-model="expansion"
                class="product__accordion"
                accordion
                flat
              >
                <v-expansion-panel
                  v-for="(header, index) in filteredExpansionHeaders"
                  :key="header"
                  class="product__accordion-item"
                  :class="{
                    'border-bottom': index !== expansionHeaders?.length - 1,
                  }"
                >
                  <v-expansion-panel-header
                    hide-actions
                    class="accordion-btn px-4"
                  >
                    <span
                      class="accordion-icon"
                      :style="
                        index === expansion
                          ? 'background-image: url(/img/icons/minus-icon.svg)'
                          : 'background-image: url(/img/icons/plus-icon.svg)'
                      "
                    ></span>
                    <h2>{{ header }}</h2>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="accordion-content">
                    <div
                      v-if="header === 'Product care'"
                      v-html="productDetails?.product?.care?.ru"
                    >
                    </div>
                    <div
                      v-else
                      v-html="productDetails?.product?.short_description?.ru"
                    >
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-12 pl-15 mt-4">
        <div
          class="recommended"
          :class="{ 'mx-n3': $vuetify.breakpoint.smAndDown }"
        >
          <CarouselComponent
            title="recommended for you"
            :items="recommendProducts"
            :product="productDetails === true"
            :api_url="baseUrl"
            @selectItem="selectItem"
            @addToCart="addToCartWithoutValidting"
            :slidesToShow="4"
            @goToProduct="
              (id) => {
                CLEAN_PRODUCT_SLUG()
                $router
                  .push({ name: 'Product', params: { id: id } })
                  .catch(() => {})
              }
            "
          />
        </div>
      </div>

      <!-- vcraft -->
      <div>
        <recursion
            v-if="productDetails?.product?.v_craft"
          :content="productDetails?.product?.v_craft[0]"
          :componentName="
            productDetails?.product?.v_craft[0]?.props?.component
              ? productDetails?.product?.v_craft[0]?.props?.component
              : productDetails?.product?.v_craft[0]?.componentName
          "
        />
      </div>
    </div>
  </div>
  <div v-else>
    <h1 style="padding: 200px; text-align: center" class="loading">Data Loading...</h1>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'

import cloneDeep from 'lodash.clonedeep'
import CarouselComponent from '@/view/content/store/CarouselComponent'
import CoolLightBox from 'vue-cool-lightbox'
import VideoModal from '../../content/store/VideoModal.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
const { mapActions, mapMutations } = createNamespacedHelpers('storeCart')
const { mapState: State_products, mapGetters: Getters_products } =
  createNamespacedHelpers('storeProducts')
const {
  mapActions: mapProductActions,
  mapState: mapProductState,
  mapMutations: product_Mutations,
} = createNamespacedHelpers('products')
const { mapActions: mapSizeChartActions, mapState: mapSizeChartState } =
  createNamespacedHelpers('sizeChart')
const { mapActions: mapOrderActions, mapState: mapOrderState } =
  createNamespacedHelpers('orderHistory')
export default {
  name: 'Product',
  components: {
    CarouselComponent,
    VideoModal,
  },
  data() {
    return {
      imageIndex: null,
      selectedImg: null,
      baseUrl: '',
      expansion: null,
      tab: 0,
      countOfProduct: 1,
      colors: [],
      selectedColor: {},
      sizes: [],
      selectedSize: null,
      expansionHeaders: [
        'Product care',
        'Additional information',
        'Size chart',
      ],
      selectedProduct: null,
      selectedGallery: [],
      social_medias: [
        {
          icon: 'fas fa-circle',
          link: 'circle',
        },
        {
          icon: 'fab fa-facebook-f',
          link: 'facebook',
        },
        {
          icon: 'fab fa-twitter',
          link: 'twitter',
        },
        {
          icon: 'fab fa-pinterest-p',
          link: 'pinterest',
        },
        {
          icon: 'fas fa-envelope',
          link: 'envelope',
        },
      ],
      openVideoModal: false,
      thickness: [],
      selectedThickness: null
    }
  },
  computed: {
    ...State_products(['products', 'reviews']),
    ...Getters_products(['getProductById']),
    ...mapProductState(['productDetails', 'products']),
    ...mapSizeChartState(['size_chart']),
    filteredExpansionHeaders () {
      return this.expansionHeaders.filter(i => i !== 'Size chart')
    },
    productCategoriesPath() {
      return []
    },
    recommendProducts() {
      if (this.products?.length) {
        return this.products?.filter(
          (i) => i?.product?.id !== this.productDetails?.product?.id
        )
      } else return []
    },
    productPrice() {
      return this.productDetails.product.sale_cost.toFixed(2)
    },
    videoUrl () {
      return this.productDetails.product.video_url || ''
    },
    isValid () {
      return Object.keys(this.selectedColor).length && this.selectedSize && this.selectedThickness
    }
  },
  watch: {
    productDetails(val) {    
      this.setMetaTags()
    },
  },
  methods: {
    getImageName,

    ...mapActions(['setSelectedProduct', 'addCartItem', 'toggleSideCart']),
    ...mapMutations(['SET_SELECTED_PRODUCT_COUNT']),
    ...product_Mutations(['CLEAN_PRODUCT_SLUG']),
    ...mapProductActions([
      'getSpecialProductColorsBySlag',
      'getSpecialProductBySlug',
      'specialProductByCategory'
    ]),
    ...mapOrderActions(['payByPaypal']),
    setMetaTags() {
      document.title = this.productDetails?.product?.name?.ru || 'Tumar.com'

      let descriptionMeta = document.querySelector('meta[name="description"]')
      if (!descriptionMeta) {
        descriptionMeta = document.createElement('meta')
        descriptionMeta.name = 'description'
        document.head.appendChild(descriptionMeta)
      }
      descriptionMeta.content =
      this.productDetails?.product?.seo_title?.ru || ''

      let keywordsMeta = document.querySelector('meta[name="keywords"]')
      if (!keywordsMeta) {
        keywordsMeta = document.createElement('meta')
        keywordsMeta.name = 'keywords'
        document.head.appendChild(keywordsMeta)
      }
      keywordsMeta.content = this.productDetails?.product?.seo_keys?.ru || ''
    },
    removeMetaTags() {
      document.title = 'Tumar.com'

      const descriptionMeta = document.querySelector('meta[name="description"]')
      if (descriptionMeta) descriptionMeta.remove()

      const keywordsMeta = document.querySelector('meta[name="keywords"]')
      if (keywordsMeta) keywordsMeta.remove()
    },
    onShareProduct(link) {
      const url = encodeURIComponent(window.location.href)
      if (link === 'facebook') {
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
        window.open(shareUrl, '_blank')
      } else if (link === 'twitter') {
        const shareUrl = `https://twitter.com/intent/tweet?url=${url}`
        window.open(shareUrl, '_blank')
      } else if (link === 'pinterest') {
        const imageUrl = encodeURIComponent(
          this.baseUrl + this.productDetails?.product?.photo
        )
        const description = encodeURIComponent(
          this.$refs.description.textContent
        )
        const shareUrl = `https://www.pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&description=${description}`
        window.open(shareUrl, '_blank')
      } else {
        return
      }
    },
    changeMainImage(image, index) {
      this.$refs.videoRef.pause()

      this.selectedImg = {
        photo: encodeURI(image),
        index,
      }
    },
    selectThickness (thickness) {
      this.selectedThickness = thickness
    },
    selectSize (size) {
      this.selectedSize = size
    },
    selectColor(color) {
      this.selectedColor = color
    },
    addToCart() {
      if (!this.isValid) {
        return
      }

      const payload = {
        ...cloneDeep(this.productDetails),
        size: this.selectedSize,
        color: this.selectedColor,
        thickness: this.selectedThickness,
        isSpecialProduct: true,
        count: this.countOfProduct
      }

      this.addCartItem(payload)
      this.toggleSideCart(true)

      this.selectedColor = {}
      this.selectedSize = null
      this.selectedThickness = null
    },
    selectItem(item) {
      this.setSelectedProduct(item)
      this.$router.push({ name: 'Special-product', params: { id: item?.id } })
    },
    isYoutube(content) {
      if (!content) {
        return false
      }

      return content.includes('<iframe');
    },
    selectVideo() {
      this.selectedImg = {photo: 'video'}

      this.$refs.videoRef.play()
    },
    addToCartWithoutValidting(item) {
      const copy = cloneDeep(item)
      copy.colors = copy?.colors[0]
      copy.colors.sizes = [copy?.colors?.sizes[0]]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    openImage(index) {
      this.imageIndex = index
    },
  },
  async created() {
    await this.getSpecialProductBySlug({
      slug: this.$route.params?.id,
    })

    await this.specialProductByCategory({ id: this.productDetails.product.categories[0]?.id })

    let result = await this.getSpecialProductColorsBySlag(this.$route.params?.id)
    this.colors = result.data
    if (this.colors.length) {
      this.selectColor(this.colors[0])
    }


    this.thickness = this.productDetails.product.thickness
    this.sizes = this.productDetails.product.dimensions
    
    this.baseUrl = this.$store.state.auth.API_URL
    this.selectedGallery = this.productDetails?.product?.gallery || []
    this.selectedGallery.push(this.productDetails.product?.photo)
    this.selectedGallery = this.selectedGallery?.map((item) => {
      return this.baseUrl + item
    })
    this.selectedImg = {
      photo: encodeURI(this.selectedGallery[this.selectedGallery?.length - 1]),
      index: this.selectedGallery?.length - 1,
    }
  },
  beforeDestroy() { 
  this.removeMetaTags()
}
}
</script>
<style lang="scss">
.description {
  .v-slide-group__wrapper {
    position: relative;
    z-index: 1;
  }
  .nav-tabs {
    border-bottom: unset;
    position: relative;
  }
  .v-tabs-bar__content {
    .v-tab {
      border-bottom: 2px solid transparent;
    }
    .v-tab.v-tab--active {
      position: relative;
      border-bottom: 2px solid;
    }
  }
  .v-tabs-slider {
    border-radius: 3px;
  }

  .v-tabs.nav.nav-tabs::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #dee2e6;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
}
</style>
<style lang="scss" scoped>

.reviews {
  display: flex;
  flex-direction: column;
}
.product-info__video {
  width: 100%;
  margin: auto 0;
}
.video-container {
  background-size: cover !important;

}
.item-color span {
  opacity: 0;
}
.item-color {
  cursor: pointer;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.product__old_price {
  text-decoration: line-through;
  font-size: 24px;
}

.item-color.active,
.item-color:hover {
  border: 1px solid #6e6e6e;
}
.v-expansion-panel-content__wrap {
  padding: 0 24px 0px;
}

.size-item.hoverable span:hover,
.size-item.active span {
  color: white;
  cursor: pointer;
}

.size-item.hoverable span:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}
.size-item.active span {
  background-color: #423f3d;
  border-color: #423f3d;
}
.size-item.disabled {
  cursor: no-drop;
  span {
    position: relative;
    color: #d3d2d2;
  }
  span:before {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 1px;
    height: 140%;
    top: 50%;
    left: 50%;
    overflow: hidden;
  }
}
.product-page {
  .breadcrumb {
    background-color: unset;
    .breadcrumb-item {
      a {
        color: #818181;
      }
    }
  }
}
.breadcrumb-item {
  padding-left: 0;
  &:before {
    content: none;
  }
  span {
    color: #818181;
  }
}
.product-info {
  &__preview,
  .picture li {
    cursor: pointer;
  }
}
.mainImg {
  display: flex;
  justify-content: center;
  width: 100%;
  // max-height: 410px;
  img {
    max-width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}
.top-right {
  img {
    cursor: pointer;
  }
}
.size__title {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 14px;
}
.v-expansion-panel--active > .v-expansion-panel-header {
  h2 {
    transition: 0.4s;
    color: #423f3d;
  }
}
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: unset;
}
.v-tab {
  text-transform: unset;
  font-size: 16px;
  letter-spacing: unset;
}
.contact-us__right {
  width: unset;
  .form__input-message {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}
.bordered {
  border: 1px solid #f2f2f2;
}
.v-expansion-panel-content.accordion-content
  .v-expansion-panel-content__wrap
  > div {
  padding-left: 31px;
}
.v-expansion-panel-content.accordion-content {
  padding-bottom: 0;
}

// ul.picture.list-unstyled {
//   height: 410px;
//   overflow: scroll;
// }

// .size ul.list-unstyled {
//   max-height: 110px;
//   overflow: scroll;
// }

// .size ul.list-unstyled:after {
//   content: '';
//   background: rgb(2, 0, 36);
//   background: linear-gradient(
//     360deg,
//     rgb(2 0 36 / 14%) 0%,
//     rgb(2 0 36 / 6%) 21%,
//     rgba(255, 255, 255, 0) 100%
//   );
//   width: 100%;
//   height: 40px;
//   position: sticky;
//   bottom: 0;
// }

.size ul.list-unstyled {
  position: relative;
}

.product-info {
  margin-top: 127px;
}

.product-ttl {
  font-size: 40px;
  color: #423f3d;
  font-weight: bolder;
}
ol.breadcrumb.pa-0 {
  margin: 0;
}
.product-width,
main .product-width {
  width: 1185px;
  max-width: 1185px !important;
}

.color h3 {
  color: #666666;
  font-size: 16px;
  font-weight: 500;
}

.item-color span {
  position: relative;
  // border-radius: 50%;
  border: 1px solid transparent;
}

.size-item {
  width: 50px;
  height: 50px;
  margin: 0 9px 9px 0;
}

.size-item span {
  display: inline-block;
  padding: 7px 12px;
  border: 1px solid #c1c1c1;
  color: #423f3d;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 2.3;
}
ul.picture.list-unstyled img {
  object-fit: cover;
}

.recommended h2 {
  margin-bottom: 0;
}
.mb-200 {
  margin-bottom: 200px;
}

.recommended .inner-image {
  height: 100px !important;
}
.p_text.bordered.border-top-none > p {
  height: 20px;
  overflow: hidden;
}

@media(max-width: 600px) {
  h1.loading {
    padding:0!important;
    margin-top: 200px;
    margin-bottom: 200px;
    font-size: 20px;
  }

  .product-info {
    margin-top: 80px;
  }
  h1.loading {
    padding: 0!important;
    margin-top: 200px;
    margin-bottom: 200px;
    font-size: 20px;
    width: 240px;
    height: 36px;
    overflow: hidden;
  }

  .col-md-5.col-12.order-md-3.order-3.col.py-0.pl-15 {
      padding: 0!important;
      position: inherit;
  }

  .product-page ol.breadcrumb.pa-0 {
      position: absolute;
      top: 0;
  }


  h1.product-ttl {
      position: absolute;
      top: 25px;
      font-size: 30px!important;
  }

  h3.product_price {
      position: absolute;
      right: 0;
      top: 25px;
  }

  .col-md-6.col-12.order-md-2.order-1.position-relative.pa-0 {
      margin-top: 40px;
  }

  .stars {
      position: absolute;
      top: -5px;
      right: 0;
  }
  .description img {
    width: 100%!important;
  }
  .row.mt-4.mb-200 .col-md-5.col-12.pl-15.mt-4 {
    padding: 10px 20px!important;
    margin: 0!important;
  }
  .social_icon ul li:first-child, .social_icon ul li:last-child {
    display: none;
  }
}
</style>
